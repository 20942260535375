const PATTERN = {
    PLATFORM: "(iOS|Android|iPhone|iPad)",
};

export const getPlatform = () => {
    const platform = window.navigator.userAgent.match(
        new RegExp(PATTERN.PLATFORM)
    );
    if (!platform) {
        return "";
    }

    if (platform[0] === "iPhone" || platform[0] === "iPad") {
        return "iOS";
    }

    return platform[0];
};
