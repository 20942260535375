import {ContentItem, SectionItem} from "../types/ContentItem";
import {getDatabase, ref, onValue} from 'firebase/database'
export class ContentRepository{
    public getDetailItem = async (contentId:string):Promise<ContentItem>=>{
        return new Promise((resolve, reject) => {
        const db = getDatabase()
            const contentRef = ref(db, `contents/${contentId}`)
            onValue(contentRef, (snapshot)=>{
                const data = snapshot.val();

                if(!data.activeNow){
                    reject('Undefined content.')
                    return;
                }

                resolve({
                    isAvailable:data.available,
                    content:JSON.parse(data.body) as SectionItem[],
                    title:data.title,
                    description:data.description,
                    reporter:data.reporter,
                    type:'mz-news',
                    footer:data.footerImage,
                })
            }, (error)=>{
                console.log(error)
            })
        })
    }
}
