import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ContentItem} from "../../types/ContentItem";
import {ContentRepository} from "../../repository/ContentRepository";
import styled from "styled-components";
import dayjs from "dayjs";
import {getPlatform} from "../../utils/Utils";
import {AOS_APP_LINK, AOS_ZAMANCHU_LINK, IOS_APP_LINK, IOS_ZAMANCHU_LINK} from "../../common/Constant";
import ReactPixel from 'react-facebook-pixel';

const HIGHLIGHT_TEXT = '하이바이'
const ZAMANCHU_TEXT = '자만추'
const HIGHLIGHT_URL_TEXT = "https://www.hibyedate.com"
const ZAMANCHU_URL_TEXT = "https://www.zamanchu.com"

export const ContentPage:React.FC = ()=>{
    const {pageId} = useParams<{pageId:string}>()
    const [pageItem, setPageItem] = useState<ContentItem|null>(null)


    useEffect(()=>{
        try{
            pageId && new ContentRepository().getDetailItem(pageId).then((result)=> {
                setPageItem(result)
                return result;
            }).then((result)=>{
                ReactPixel.pageView()

                ReactPixel.track('ViewContent', {
                    content_ids: pageId,
                    content_name: result.title,
                    content_type: result.type,
                    currency: 'KRW',
                })
            })
        }catch(ex){

        }
    }, [pageId])

    const onPressHighlight = useCallback((part: string)=>{

        if(!pageItem) return;
        if(!pageItem.isAvailable) return;

        if (part === '자만추' || part === 'https://www.zamanchu.com') {
            if (getPlatform() === "iOS") {
                window.open(IOS_ZAMANCHU_LINK);
            } else {
                window.open(AOS_ZAMANCHU_LINK);
            }

        } else {
            if (getPlatform() === "iOS") {
                window.open(IOS_APP_LINK)
            }
            window.open(AOS_APP_LINK)
        }
    }, [pageItem])

    const getHighlightedText = useCallback<(text:string)=>ReactNode> ((text) =>{
        // Split text on higlight term, include term itself into parts, ignore case
        const parts = text.split(new RegExp(`(${HIGHLIGHT_TEXT}|${ZAMANCHU_TEXT}|${ZAMANCHU_URL_TEXT}|${HIGHLIGHT_URL_TEXT})`, "gi"));
        return parts.map((part, index) => {
            const Comp = pageItem?.isAvailable ? ActiveLink : InActiveLink
            return (
                <React.Fragment key={index}>
                    {part === HIGHLIGHT_TEXT || part === ZAMANCHU_TEXT || part === ZAMANCHU_URL_TEXT || part === HIGHLIGHT_URL_TEXT? (
                        <Comp onClick={() => onPressHighlight(part)} >{part}</Comp>
                    ) : (
                        part
                    )}
                </React.Fragment>
            )
        });
    }, [onPressHighlight, pageItem?.isAvailable] )



    if(!pageId) return null;

    if(!pageItem) return null;

    return (
        <div>
            {
                pageItem.type === 'mz-news' && (
                    <>
                        <img src={'https://firebasestorage.googleapis.com/v0/b/hibye-9e1e4.appspot.com/o/header.png?alt=media&token=c41e0b2f-ad96-4551-968b-3d7f1415d8e3'} width="100%" alt={'header'} />
                        <TitleWrapper>
                            <HeaderText>
                                {pageItem.title}
                            </HeaderText>
                            <Row>
                                <ReporterText>
                                    {pageItem.reporter}
                                </ReporterText>
                                <ReporterText style={{ marginLeft: 8 }}>
                                    {dayjs().subtract(3, "hours").format("YYYY-MM-DD HH:mm")}
                                </ReporterText>
                            </Row>
                        </TitleWrapper>
                        {pageItem.content.map((item, index)=>{

                            if(item.type === 'image'){
                                return (
                                    <Content key={index.toString()}>
                                        <img alt={item.caption} src={item.imageUrl} width="100%" />
                                        <CaptionText>
                                            {item.caption}
                                        </CaptionText>
                                    </Content>
                                )

                            }
                            if(item.type === 'text'){
                                return (
                                    <Content key={index.toString()}>
                                        <ContentText>
                                            {getHighlightedText(item.content)}
                                        </ContentText>
                                    </Content>
                                )
                            }

                            if(item.type === 'copyright'){
                                return (
                                    <Content key={index.toString()}>
                                        <ContentText>
                                            Copyright ⓒ MZ뉴스 - 무단복제 및 전재 - 재배포금지
                                        </ContentText>
                                    </Content>
                                )
                            }

                            if(item.type === 'space'){
                                return (
                                    <div style={{height:item.space}} key={index.toString()}>
                                    </div>
                                )
                            }
                            return null;
                        })}
                        <img alt={'mz-footer'} src={pageItem.footer} width="100%" />
                    </>
                )
            }
        </div>
    );
}
const DEFAULT_TEXT_COLOR = 'black';

const TitleWrapper = styled.div`
  padding: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 26px;
  padding: 0 16px;
`;

const HeaderText = styled.p<{color?:string}>`
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 24px;
    line-height: ${24*1.5}px;
    color: ${(props) => props.color || DEFAULT_TEXT_COLOR};
`


const ReporterText = styled.p`
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 13px;
    line-height: ${13*1.5}px;
    color: #A3AAB2;
`


const ContentText = styled.p<{color?:string}>`
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 16px;
    line-height: ${16*1.5}px;
    color: ${(props) => props.color || DEFAULT_TEXT_COLOR};
`

const CaptionText = styled.p<{color?:string}>`
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 13px;
    line-height: ${13*1.5}px;
    color: #A3AAB2;
`
const InActiveLink = styled.button`
  font-family: 'Pretendard';
  color: #090E18;
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
`;
const ActiveLink = styled.button`
  font-family: 'Pretendard';
  color: #3CB3C1;
  font-size: 16px;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
`;
