import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {GlobalStyles} from "./common/GlobalStyles";
import {ContentPage} from "./pages";
import { initializeApp } from 'firebase/app';
import ReactPixel from "react-facebook-pixel";

ReactPixel.init('621792646730460')
initializeApp({
    apiKey: "AIzaSyAyJbJ-XlpqR44AIYaO80y159rDUKJp43A",
    authDomain: "hibye-9e1e4.firebaseapp.com",
    databaseURL: "https://hibye-9e1e4-default-rtdb.firebaseio.com",
    projectId: "hibye-9e1e4",
    storageBucket: "hibye-9e1e4.appspot.com",
    messagingSenderId: "643415452329",
    appId: "1:643415452329:web:4e2914335cdc011c0997b8",
    measurementId: "G-2MSWJ5GJCK"
});

function App() {
  const routes = createBrowserRouter([
    {
      path:'/:pageId',
      element: <ContentPage/>
    }
  ])

 return (
     <>
       <GlobalStyles/>
       <RouterProvider router={routes}/>
     </>
 )
}

export default App;
